﻿//Resets
@import "./Layout/_lobiBoxResets.scss";
@import "./Layout/_bootstrapResets.scss";
@import "./Layout/_datePickerResets.scss";
@import "./Layout/_signaturePadReset.scss";

//Sections
@import "./Layout/_menus.scss";
@import "./Layout/_accountLogin.scss";
@import "./Layout/_footer.scss";
@import "./Layout/_availablePlans.scss";
@import "./Layout/_applicationLayout.scss";
@import "./Layout/_quotes.scss";
@import "./Layout/_enrollments.scss";
@import "./Layout/_errorPages.scss";

//Components
@import "./Layout/_filterGroups.scss";
@import "./Layout/_productCard.scss";
@import "./Layout/_waitAnimation.scss";
@import "./Layout/_indexPages.scss";

//Working STYLES

//Legacy
.hidden {
    display: none !important;
}

//Page Titles
.pg-title {
    margin-top: 0;
    font-size: 1.25rem;

    i {
        margin-right: .25rem;
    }
}

.pg-subtitle-application-title {
    font-size: 1rem;
}

.pg-subtitle-application-type {
    font-size: .75rem;
}
//Steps-Buttons
.btn-global-left {
    $shadow-color: mix($stepBackButtonBGColor, #000);

    background-color: $stepBackButtonBGColor;
    color: $stepBackButtonTextColor;
    position: absolute;
    top: 400px;
    left: .25rem;
    z-index: 1000;
    box-shadow: 0px 2px 2px 0px rgba($shadow-color, 1);

    &:hover {
        background-color: darken($stepBackButtonBGColor,10%);
        color: darken($stepBackButtonTextColor,8%);
        box-shadow: inset 0px 2px 2px 0px rgba($shadow-color, 1);
    }
}

.btn-global-right {
    $shadow-color: mix($stepNextButtonBGColor, #000);

    background-color: $stepNextButtonBGColor;
    color: $stepNextButtonTextColor;
    position: absolute;
    top: 400px;
    right: .25rem;
    z-index: 1000;
    box-shadow: 0px 2px 2px 0px rgba($shadow-color, 1);

    &:hover {
        background-color: darken($stepNextButtonBGColor,10%);
        color: darken($stepNextButtonTextColor,8%);
        box-shadow: inset 0px 2px 2px 0px rgba($shadow-color, 1);
    }
}

//Exchange App Nav

.exchange-app-menu-item i {
    color: $stepNavPrimaryColor;
}

.text-exchange-icon {
    color: $stepNavPrimaryColor;
}

.bg-exchange-status-bar {
    background-color: $stepNavPrimaryColor;
}

//Subscriber Cards

.dependent-card {
    box-shadow: 1px 1px 20px 1px #ebebeb;
    font-size: 12px;
}

//Effects

.box-shadow {
    box-shadow: 2px 3px 11px 0px #cfcfcf;
}

.colorBox {
    border: 60px solid #892041;
    border-style: ridge;
}

//Modal style

.modal-headStyle {
    color: #fff;
    font-weight: bold;
}

.textStyle {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.instructStyle {
    font-family: Tahoma;
    font-size: 70%;
}

.resultDetails {
    font-weight: bolder;
    font-size: 150%;
}

.main-container, .modal-body {
    background-color: $mainContainerBackgroundColor;
}

.modal-footer, .footModal {
    background-color: $color-1;
}

.drop-zone {
    width: 100%;
    height: 70px;
    border: 5px;
    border-color: $headerBackgroundColor;
    border-style: groove;
    background-color: #fff;
}

.Checkbox-Alignment {
    float: left;
}

.btn-primary, .btn-new {
    background-color: $stepNextButtonBGColor;
    color: $white;
    border-color: $stepNextButtonBGColor;
    font-weight: bold;
}
.btn-secondary {
    background-color: $white;
    color: $color-1;
    border-color: $color-1;
    border-width: 2px;
}

.btn-index-card-edit, .btn-index-card-delete, .btn-index-search {
    background-color: $stepBackButtonBGColor;
    color: $white;
    border-color: $stepBackButtonBGColor;
    font-weight: bold;
}

.btn-index-card-delete:hover, .btn-index-card-edit:hover, .btn-index-search:hover, .btn-primary:hover, .btn-new:hover {
    background-color: $white;
    color: $stepBackButtonBGColor;
    border-color: $stepBackButtonBGColor;
    font-weight: bold;
}

.btn-primary.disabled, .btn-primary:disabled {
    color: black;
    background-color: $color-6;
    border-color: black;
}

//Home Page

/* this could be used to find more pictures for free
https: //www.freepik.com/pricing*/
.header-image {
    /*image reference https://www.freepik.com/free-photo/happy-family-lying-row_857627.htm#query=happy%20family%20outside&position=8&from_view=search*/
    background-image: url('../../../Images/Home/happy-family-lying-row.jpg');
    padding-block: 10%;
    background-size: cover;
    background-position: center;
    background-position: 0% 49%;
}

.header-text {
    height: 10%;
    color: white;
    text-align: center;
    opacity: 0.7;
    background-color: $color-4;
}

.navigation-container {
    padding-top: 40px;
}

.box-nav {
    width: 20%;
    border: 5px solid red;
}

.block-link {
    width: 285px;
    height: 360px;
    display: inline-table;
    color: white;
    background-color: #464747;
    border: solid $headerBackgroundColor;
    margin-bottom: 3%;
    margin-right: 1%;
    text-decoration: none !important;
}

.block-link-img {
    width: 124%;
    position: relative;
    bottom: 32px;
    right: 20px;
}

.link-title {
    background-color: $headerBackgroundColor;
    height: 73px;
}

.link-description {
    background-color: $headerBackgroundColor;
    position: relative;
    bottom: 54px;
    height: 34%;
    width: 100%;
}

.card-container-header {
    margin-bottom: 1%;
}

.card-description {
    margin-top: 5px;
}

.card-id {
    font-size: x-small;
    color: $color-2;
}

.alert-notification {
    font-size: large;
    float: left;
    background-color: grey;
    width: 22px;
    border-radius: 50%;
    position: relative;
    left: 10px;
    top: 15px;
}

.alert-bell {
    font-size: medium;
    position: relative;
    right: 3px;
}

.date-style {
    font-size: small;
    position: relative;
    right: 25px;
    bottom: 38px;
}

.icon-color {
    color: $color-1-1;
}

.employee-card {
    margin-top: 5px;
    padding: 40px;
}

.editor-block .editor-block-label {
    background-color: #49a585;
    color: white;
}

.display-block-control {
    background-color: white;
}

input[type=checkbox] {
    accent-color: #8a1d40;
}

.home-card-bg {
    background-color: $color-2;
}

.nav-tabs {
    > .nav-item {
        margin-right: 4px;
        color: $black;
        background-color: $color-6;

        > .nav-link {
            color: $black;
            background-color: $color-6;
        }

        &.active {
            background-color: $color-4;
            color: $white;

            > .nav-link {
                color: $white;
                background-color: $color-4;
            }
        }
    }

    
}

.index-card-col {
    > div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &.single-col {
        max-width: 53rem;
    }
}