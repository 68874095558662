﻿
span.icon-list.icon-list-sm {
    font-size: .85em;
}

.icon-list-sm {
    font-size: .8em;
}

.product-lbl {
    font-size: 1.1em;
    font-weight: 600;
}

.product-lbl-md {
    font-size: 1.3em;
    font-weight: normal;
}

.product-lbl-lg {
    font-size: 1.5em;
    font-weight: normal;
}


.pro-mod {
    font-style: italic;
    color: #81A1BF !important;
}

.pro-mod-sm {
    font-size: .8em;
}

.plan-id {
    font-size: .8em !important;
    line-height: 1.1em;
    font-style: italic;
    word-break: break-all;
}

.pro-lbl {
    color: $primary;
}

.ins-lbl {
    color: $primary;
}

.exc-lbl {
    color: $primary;
}

.met-lbl {
    color: $primary;
}

.carrier-logo {
    max-width: 150px;
}

.ext-logo {
    max-width: 110px !important;
}

.ext-logo-lg {
    max-width: 150px !important;
}

.plan-chk {
    top: -.5em;
    margin: 0 0 1em 2em;
}

.plan-chk span {
    font-size: 1.1em;
    display: inline-block;
    margin-top: -.2em;
}

.right-check {
    float: right !important;
    margin-left: .2em !important;
    margin-top: .2em !important;
}
