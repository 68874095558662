﻿/// <reference path="../../../Themes/ABXCH/_variables.scss" / >
/// <reference path="../../../Libraries/Bootstrap/scss/_variables.scss" />

//CarrierApplication Buttons
.carrier-application-container {
    width:auto;
    margin-right: 100px;
}

.application-buttons {
    top: 150px;
    right: 20px;
    transform: translateZ(0);
}


.application-buttons button {
    box-shadow: 1px 1px 1px 1px #969696;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 12px;
    width:80px;
}


$white: #fff;

.btn-application
{
    @extend %defaultButtonColor;
}

//



.status-heading-generate {
    text-align: center;
    padding: 0.5em;
}

.appques {
    color: #567282;
    text-transform: uppercase;
    background: none;
    font-size: .9em;
}

.apptopques {
    color: #567282;
    text-transform: uppercase;
    background: none;
    font-size: 1.25em;
    margin: .5em 0 .2em .1em;
}

.apptopques-lg {
    color: #567282;
    text-transform: uppercase;
    background: none;
    font-size: 1.25em !important;
    margin: .5em 0 .2em .1em;
}

.appsubtitle {
    text-transform: uppercase;
    font-style: italic;
}

.app-disclaimer {
    text-align: justify;
    color: rgb(99, 109, 113);
    font-size: .95em;
}

.app-focus-disclaimer {
    text-align: justify;
    color: rgb(99, 109, 113);
    font-size: 1.2em;
}

//Validation Messages
.validation-message-header-link {
    text-decoration: underline;
    cursor: pointer;
}
