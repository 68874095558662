﻿/// <reference path="../../../../Libraries/Bootstrap/scss/variables" / >
@media (min-width: 769px) {
    .subsidy-estimate {
        border: solid 1px #b7b7b7;
        border-top-width: 0px;
        border-bottom-width: 0px;
    }
}

.product-card {

    .product-card-header {
        background-color: mix($primary, #e2e2e2, 4%);
    }

    img {
        width: 100%;
        max-width: 200px;
        margin: 0 auto;
        display: block;
    }
}

.application-not-allowed {
    font-size: .6rem;
    background-color: #eee;
}

.exchange-product-card {
    font-size: .75rem;

    p {
        margin-top: 0;
        margin-bottom: 0;
    }

    .row {
        margin-top: 0;
        margin-bottom: 0;
    }
}
