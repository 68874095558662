﻿
.footer {
    min-height: 8em;
    background-color: $footerBackgroundColor;
    color: #ffffff;
    margin: 40px -30px -30px -30px;
    padding: 30px;
}


.footer .disclaimer {
    font-size: .8em;
    white-space: normal;
}

.copyright {
    font-family: "teko", sans-serif;
    font-size: 1.5em;
}

.copyright p {
    font-size: 1.2em;
    padding: 2em;
    color: #a6c1db;
}

.footer a {
    color: $footerLinkColor;
}

.footer a:hover {
    text-decoration: underline;
}

.footer a:active {
    color: #17c426;
}

.copyright a:hover, .copyright a:visited, .copyright a:link {
    color: $primary;
    text-decoration: none;
    vertical-align: top;
}

.opac {
    background: rgba(1,26,51,0.85);
}

.footer .h2 {
    color: #dfe0e1;
    font-size: 1.9em;
}

.footer .h3 {
    color: #babfc1;
}

.footer p {
    color: #f6f6f6;
    font-size: 1.1em;
}

.footer #LangSelector {
    cursor: pointer;
}

.footer #LangSelector li:hover {
    cursor: pointer;
    text-decoration: underline;
    -webkit-transition: color 0.25s linear, background-color 0.25s linear, border-color 0.25s linear;
    -moz-transition: color 0.25s linear, background-color 0.25s linear, border-color 0.25s linear;
    -ms-transition: color 0.25s linear, background-color 0.25s linear, border-color 0.25s linear;
    -o-transition: color 0.25s linear, background-color 0.25s linear, border-color 0.25s linear;
    transition: color 0.25s linear, background-color 0.25s linear, border-color 0.25s linear;
}

.footer #LangSelector li.active {
    color: #54ff72;
}

.footer .list-inline > li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px;
}