﻿// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.


// ************************
// Color Theme AVERA
// ************************

$dark-green:#006547;
$light-green: #00945e; 
$black:#231f20;  
$dark-grey:#393536;
$light-grey:#bdbcbc; 
$lighter-grey:#e9e9e9;

$plum: #8a1d40;
$orange:#df864f;
$spring-green:#49a585;

$color-1:$dark-green;
$color-2: $light-green;
$color-3: $black;
$color-4:$dark-grey;
$color-5:$light-grey;
$color-6 :$lighter-grey;
$color-1-1: $plum;
$color-1-2: $orange;
$color-1-3: $spring-green;


$gray-600: #006547;
$teal: #006547;
$plum: $color-1; 
$paleOrange: #8a1d40;

$info: $paleOrange;

$iconColor: #8a1d40;

$component-active-bg: $plum;


// *******
// Gradients
//********
%bg-gray-gradient
{
    background: rgb(245,245,245);
    background: -moz-linear-gradient(top, rgba(245,245,245,1) 0%, rgba(236,236,236,1) 47%, rgba(228,228,228,1) 100%);
    background: -webkit-linear-gradient(top, rgba(245,245,245,1) 0%,rgba(236,236,236,1) 47%,rgba(228,228,228,1) 100%);
    background: linear-gradient(to bottom, rgba(245,245,245,1) 0%,rgba(236,236,236,1) 47%,rgba(228,228,228,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f5f5', endColorstr='#e4e4e4',GradientType=0 );
    border-bottom: 1px solid #e2e2e2;
}

%bg-white-gradient
{
    background: rgb(255,255,255);
    background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(239,239,239,1) 100%);
    background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(239,239,239,1) 100%);
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(239,239,239,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#efefef',GradientType=0 );
}

%bg-gray-gloss-gradient
{
    background: rgb(242,245,246);
    background: -moz-linear-gradient(top, rgba(242,245,246,1) 0%, rgba(227,234,237,1) 37%, rgba(200,215,220,1) 100%);
    background: -webkit-linear-gradient(top, rgba(242,245,246,1) 0%,rgba(227,234,237,1) 37%,rgba(200,215,220,1) 100%);
    background: linear-gradient(to bottom, rgba(242,245,246,1) 0%,rgba(227,234,237,1) 37%,rgba(200,215,220,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f5f6', endColorstr='#c8d7dc',GradientType=0 );
}

%bg-dark-gradient
{
    background: rgb(69,72,77);
    background: -moz-linear-gradient(top, rgba(69,72,77,1) 0%, rgba(0,0,0,1) 100%);
    background: -webkit-linear-gradient(top, rgba(69,72,77,1) 0%,rgba(0,0,0,1) 100%);
    background: linear-gradient(to bottom, rgba(69,72,77,1) 0%,rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#45484d', endColorstr='#000000',GradientType=0 );
}


// *******
// Buttons
//********
//Default
%defaultButtonColor
{
    @extend %bg-gray-gloss-gradient;
    color: #000;
}

$IconColor: $plum;

//Steps
$stepBackButtonBGColor: $plum;
$stepBackButtonTextColor: #FFF;
$stepNextButtonBGColor: $plum;
$stepNextButtonTextColor: #FFF;
$stepNavPrimaryColor: $plum;

//Index Button Maps
$indexButtons: "index-search", "index-card-edit", "index-card-view", "index-card-delete", "new", "index-choose", "product-rate";
$indexButtonBGColors: ( "index-search": $plum, "index-card-edit": $plum, "index-card-view": $plum, "index-card-delete": $plum, "new": $plum, "index-choose": $plum, "product-rate": $plum);
$indexButtonTextColors: ( "index-search": $white, "index-card-edit": $white, "index-card-view": $white, "index-card-delete": $white, "new": $white, "index-choose": $white, "product-rate": $white);


// *******
// Body
//********
$link-color: $plum;
$bodyBackgroundColor: #006547;
$bodyTextColor: #006547;
$waitAnimationColor: $plum;


// *******
// Header
//********
$headerBackgroundColor: $teal;


// *******
// Menus
//********
$menuAccentColor: $teal;
$NavbarDropdownLinkColor: #FFF;
$mobileMenuHamburgerColor: #FFF;
$sidebarMenuBackgroundColor: #FFF;



// *******
// Footer
//********
$footerBackgroundColor: $gray-600;
$footerLinkColor: #FFF;



