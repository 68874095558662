﻿
$input-placeholder-color: #00945e;

$mainContainerBackgroundColor: #bdbcbc;
$white: White;

$color-1: $white;
$color-2: $white;
$color-3: $white;
$color-4: $white;
$color-5: $white;
$color-6: $white;
$color-1-1: $white;
$color-1-2: $white;
$color-1-3: $white;