﻿
.index-main-heading
{
    color: white;

    button
    {
        color: white !important;

        :hover
        {
            opacity: .85;
        }
    }

    @extend %bg-dark-gradient;
}

.index-card
{
    font-size: 14px;
    color: #444;
    @extend %bg-white-gradient;

    .btn
    {
        margin-left: .25rem;
        margin-right: .25rem;
    }
}



@if global-variable-exists(iconColor)
{

    .index-card *:not(.btn) > i,
    #aCreateExchangeApplication > i
    {
        color: $iconColor;
    }

}


.index-card-title
{
    color: $color-1;
    font-weight: bold;
    font-size: 1.75rem;
}

.index-card-status
{
    font-size: .8rem;
}

.index-card-subtitle
{
    color: #000;
    font-weight: bold;
    font-size: 1.15rem;
}


@each $name in $indexButtons
{

    $bg-color: map-get($indexButtonBGColors, $name);
    $shadow-color: mix($bg-color, #000);
    $text-color: map-get($indexButtonTextColors, $name);

    .btn-#{$name}
    {
        background-color: $bg-color;
        color: $text-color;
        box-shadow: 0px 2px 2px 0px rgba($shadow-color, 1);

        &:hover
        {
            background-color: darken($bg-color,10%);
            color: darken($text-color,8%);
            box-shadow: inset 0px 2px 2px 0px rgba($shadow-color, 1);
        }

        &:disabled
        {
            box-shadow: none !important;
            background-color: $bg-color;
            cursor: not-allowed;
        }
    }
}

.index-action-section .btn
{
    margin-bottom: .5rem;
}

.btn-default
{
    @extend %defaultButtonColor;
    box-shadow: 0px 2px 2px 0px rgba(#999, 1);

    &:hover
    {
        box-shadow: inset 0px 2px 2px 0px rgba(#999, 1);
    }
}


//Filters
.filter-group-header
{
    @extend %bg-gray-gradient;
}
