﻿
//Remove margin from label
label
{
    margin-bottom: 0;
    display: inline;
}


//Checkbox size
input[type="checkbox"]
{
    width: 1rem;
    height: 1rem;
    vertical-align: middle;
    margin-right: .25rem;
}

//Editor block styles

.editor-block {
    margin-bottom: .25em;
    width: 100%;
    display: flex;

    .editor-block-label {
        padding: .35rem;
        background-color: #eee;
        width: 150px;
        font-weight: bold;
        text-align: right;
        margin-right: .25rem;
    }

    .editor-block-control {
        background-color: #fff;
        padding: .35rem;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .display-block-control {
        background-color: #eeeeee;
        color: #606060;
        padding: .35rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    input {
        padding: .35rem;
    }

    input[type="text"], input[type="password"], textarea, input[type="date"], select, input[type="tel"], input[type="number"], input[type="email"] {
        width: 100%;
        border: none;
    }
}


.editor-block-yes-no
{
    margin-bottom: .25rem;
    background-color: #fff;
    padding: .75rem;

    .editor-block-label
    {
        font-weight: bold;
    }

    .editor-block-control
    {
        display: flex;
        flex-direction: column;
    }
}
