﻿
$quoteBodyColor: #e9e9e9;
$quoteBorderColor: scale-color($quoteBodyColor, $lightness: -5%);
$readyTabColor: scale-color($quoteBodyColor, $lightness: -12%);
$disabledTabColor: scale-color($quoteBodyColor, $lightness: -23%);
$disabledTextColor: scale-color($disabledTabColor, $lightness: -15%);
$quoteTabHoverBgColor: $quoteBodyColor;



.nav-stepper-body-container{
    background-color: $quoteBodyColor;
    border: 1px $quoteBorderColor solid;
    padding: 2.5rem;
    height: 100%;
   
}

.nav-stepper-main {
    padding: 0;
    margin-bottom: -1px;

    label {
        display: inline-block;
        font-size: 1em;
        margin-bottom: .25em;
        color: #393536;
    }


    li {
        display: inline-block;
        z-index: 100;
        position: relative;
    }

    li button {
        border: 1px $quoteBorderColor solid;
        color: #000 !important;
        font-weight: bold;
        padding: .5em 1em;

        &:hover {
            text-decoration: none;
        }
    }

    li.active {
        border-top: 3px $stepNavPrimaryColor solid;
        border-radius: 2px;
    }

    li.active button {
        background-color: $quoteBodyColor;
        color: #000 !important;
        border-bottom-color: $quoteBodyColor;
        cursor: default;
    }

    .ready *:hover {
        cursor: pointer;
        background-color: $quoteTabHoverBgColor;
        border-bottom-color: $quoteBodyColor;
    }

    li.ready button {
        background-color: $readyTabColor;
        cursor: pointer;
    }

    .disabled *:hover {
        cursor: not-allowed;
    }


    li.disabled button {
        background-color: $disabledTabColor;
        color: $disabledTextColor !important;
    }
}


