﻿.logo-container {
    height: 50px;
}

.benefit-container {
    height: 800px;
    overflow: scroll;
}

.inline-error {
    border-color: #FF0C0C;
    background-color: rgba(232, 15, 15, 0.9);
    color: #ffffff;
    position: static;
    font-size: .9em;
    height: auto;
    border-radius: 0;
    border: 1px solid transparent;
    -webkit-box-shadow: 2px 2px 5px #aaaaaa;
    box-shadow: 2px 2px 5px #aaaaaa;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    text-transform: uppercase;
    font-family: 'Teko', sans-serif;
    font-size: 1em;
    display: inline-block;
    padding: .03em .2em;
}
