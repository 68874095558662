﻿/// <reference path="../../../../Themes/ABXCH/_variables.scss" / >
/// <reference path="../../../../Libraries/Bootstrap/scss/_variables.scss" / >

.lobibox > * {
    word-break: break-word;
}

.lobibox-notify-wrapper * {
    text-transform: uppercase;
    background-color: $info !important;
    color: #fff !important;
    border-color: $info !important;
}

.lobibox-notify-wrapper.top {
    top: 12%;
}

.lobibox-notify-wrapper.top.right *{
    color: #fff !important;
}


//Master Reset
.lobibox
{
    border: none !important;

    .lobibox-body, .lobibox-header, .lobibox-footer
    {
        background-color: #fff !important;
        color: #444 !important;
        border-color: none !important;
    }

    .lobibox-header
    {
        color: #000 !important;
    }

    .lobibox-icon
    {
        color: $info !important;
    }
}





//buttons


.lobibox-btn:not(.lobibox-btn-yes)
{
    color: #000 !important;
    background-color: #eee !important;
    border-color: #dadada !important;

    &:hover
    {
        background-color: darken(#eee, 15%) !important;
    }
}

.lobibox-btn-yes
{
    color: #FFF !important;
    background-color: $info !important;

    &:hover
    {
        background-color: darken($info, 15%) !important;
    }
}

