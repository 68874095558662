﻿.icon-svg-sm {
    width: 100%;
}

.card.not-created {
    box-shadow: none;

    .card-header {
        background-color: white;
        border-bottom: none;
    }

    label {
        color: grey !important;
    }
}

.enrollmentButtonSubmit {
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
}

.submissions-overflow-hide {
    overflow: hidden;
}
