﻿
.typeItDesc, .drawItDesc {
    background-color: $primary;
}

.sigNav a.current, .sigNav a.current:link, .sigNav a.current:visited {
    text-transform: uppercase;
    font-weight: 600;
}

.sigNav li a {
    color: $primary;
}

.sigNav a:hover {
    color: $primary;
}

.sigNav {
    height: 2.25em;
    margin: 0;
    padding: 0;
    position: relative;
    list-style-type: none;
}

    .sigNav a {
        pointer-events: none;
        font-size: .8em;
        bottom: -15px;
        font-family: 'Arimo',sans-serif;
    }


.sigPad label.error {
    color: #f33;
}

.sigPad input.error {
    border-color: #f33;
}

.sigPad button:hover {
    background-color: #333;
    color: #fff;
}

.sig {
    display: none;
}

.sigNav {
    display: none;
    height: 2.25em;
    margin: 0;
    padding: 0;
    position: relative;
    list-style-type: none;
}

    .sigNav .nav-tabs > li > a {
        color: #82b5cc;
        border-color: #C4D7F5;
        border-bottom: #82b5cc;
    }

.sigNav a.current,
.sigNav a.current:link,
.sigNav a.current:visited {
    background-color: #ffffff;
    -moz-border-radius-topleft: 3px;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-left-radius: 3px;
    -webkit-border-top-right-radius: 3px;
    border-radius: 3px 3px 0 0;
    color: $primary;
    text-decoration: none;
    border: 2px solid $primary;
    border-bottom: transparent;
    margin-bottom: -3px;
}

.sigNav .typeIt a.current,
.sigNav .typeIt a.current:link,
.sigNav .typeIt a.current:visited {
    color: $success;
    text-decoration: none;
    background-color: #ffffff;
}

.sigPad .nav-tabs > li > a {
    color: $success;
    text-decoration: none;
    background-color: #ffffff;
}

    .sigPad .nav-tabs > li > a:hover {
        color: $success;
        text-decoration: none;
        background-color: #ffffff;
        border-color: $primary;
    }

.sigWrapper {
    clear: both;
}

    .sigWrapper.current {
        display: inline-block !important;
    }

.signed .sigWrapper {
    border: 0;
}

.pad {
    position: relative;
    -ms-touch-action: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    touch-action: none;
}

.typed {
    height: 55px;
    margin: 0;
    padding: 0 5px;
    position: absolute;
    z-index: 90;
    cursor: default;
    font-size: 2em;
    color: #145394;
    font: normal 1.875em/50px "Journal",Georgia,Times,serif;
}

p.error {
    display: block;
    margin: 0.5em 0;
    padding: 0.4em;
    background-color: #f33;
    color: #fff;
    font-weight: bold;
}

.sigPad {
    margin: 0;
    padding: 0;
    width: 100%;
}

    .sigPad input {
        cursor: pointer;
        font-size: 1.3em;
        display: block;
        width: 100%;
        padding: .195em .22em;
        background-color: #ffffff;
        background-image: none;
        border-radius: 3px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
        -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        -moz-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    }