﻿.open-datepicker {
    cursor: pointer;
    z-index: 155;
    top: 25em;
}

.datecontrol {
    cursor: pointer;
}

.datepicker {
    z-index: 1000;
    cursor: pointer;
}

.datepicker.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 10px;
    margin: .5em;
    text-align: left;
    list-style: none;
    background-color: rgba(246, 246, 246, 0.94);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 0;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

.datepicker-switch {
    text-align: center;
    font-weight: normal;
    font-size: 1.2em;
}

.datepicker .prev {
    font-size: 2em;
}

.datepicker .next {
    font-size: 2em;
}

.datepicker .day {
    font-size: 1.3em;
    border: #ffffff 2px solid;
    margin: 2px;
    padding: .2em;
}

.datepicker .day:hover {
    color: $primary;
}

.datepicker .new.day {
    color: $primary;
}

.datepicker .new.day:hover {
    color: $primary;
}

.datepicker .active.day {
    color: $primary;
    background-color: #ffffff;
}

.datepicker .old.day {
    color: #a6c1db;
}

.datepicker .dow {
    font-weight: normal;
}