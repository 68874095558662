﻿
.open-right {
    right: 0 !important;
}

.option-nav {
    font-family: 'Oswald', sans-serif;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    z-index: 1;
    position: inherit;
    /*width: 200px;*/
    margin: 0 auto;
}

.option-nav ul li {
    border-bottom: 1px solid #e7e7e7;
}

.option-nav-slider-right {
    position: relative;
    left: 2000px;
    opacity: 0;
    clear: both;
    height: 0;
}

.option-nav-open-right {
    left: 0 !important;
    opacity: 1;
    height: auto;
}

.option-nav-transition-left {
    -webkit-transition: left 0.3s ease-out;
    /* Chrome 1-25, Safari 3.2+ */
    -moz-transition: left 0.3s ease-out;
    /* Firefox 4-15 */
    -o-transition: left 0.3s ease-out;
    /* Opera 10.50–12.00 */
    transition: left 0.3s ease-out;
    /* Chrome 26, Firefox 16+, IE 10+, Opera 12.10+ */;
}



.option-sidebar-nav li:before {
    background-color: transparent;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: width 0.2s ease-in;
    width: 0px;
    z-index: -1;
}

.option-sidebar-nav li:hover:before {
    width: 100%;
}

.option-sidebar-nav li.active a {
    background-color: transparent;
    color: $primary;
}

.option-sidebar-nav li a {
    background-color: transparent;
    color: black !important;
}

.option-sidebar-nav li a:hover {
    background-color: transparent;
    color: $primary;
}

.option-sidebar-nav li.open:hover :before {
    width: 100%;
}

.option-sidebar-nav .dropdown-menu {
    background-color: #efefef;
    border-radius: 0;
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
}

.option-sidebar-nav li a:hover,
.option-sidebar-nav li a:active,
.option-sidebar-nav li a:focus,
.option-sidebar-nav li.open a:hover,
.option-sidebar-nav li.open a:active,
.option-sidebar-nav li.open a:focus {
    background-color: transparent;
    color: #f7f7f7;
    text-decoration: none;
}
