﻿.error-pg h1 {
    font-size: 5em;
    color: #f00;
    text-transform: none;
    text-shadow: 2px 2px 5px rgba(150, 150, 150, 0.77);
}

.error-pg h2 {
    font-size: 2.8em;
    color: $primary;
    font-weight: bold;
    text-shadow: 2px 2px 5px rgba(150, 150, 150, 0.77);
}

.error-pg .text-row {
    position: fixed;
    bottom: 30px;
    margin-left: 100px;
}

.error-pg .img-pet {
    position: fixed;
    bottom: 0;
}

.extra-lg {
    font-size: 20em;
    margin: 0 0 0 115px;
    color: #e10000;
    -webkit-text-stroke: .01em #ffffff;
    z-index: 3;
    text-shadow: 2px 2px 5px rgba(150, 150, 150, 0.77);
}

.error-pg .xx-lg {
    font-size: 200%;
    margin: 0 auto;
    z-index: -1;
    color: #e10000;
    text-shadow: 2px 2px 5px rgba(150, 150, 150, 0.77);
}

.error-pg .home-link {
    text-transform: none;
    font-size: 3em;
    padding: .2em 0 .2em 0;
    color: $success;
}

.error-layout .sorrytitle {
    color: #f00;
}

.error-layout h1 {
    font-size: 5em;
    color: #f00;
    text-transform: none;
    text-shadow: 2px 2px 5px rgba(150, 150, 150, 0.77);
}

.error-layout h2 {
    font-size: 2.8em;
    color: $primary;
    font-weight: bold;
    text-shadow: 2px 2px 5px rgba(150, 150, 150, 0.77);
}

.error-layout .text-row {
    margin-left: 100px;
    position: relative;
}

.error-layout .img-responsive.img-pet {
    bottom: 0;
    position: fixed;
    max-width: 550px;
}

.error-layout .extra-lg {
    font-size: 20em;
    margin: 0 0 0 15px;
    color: #e10000;
    -webkit-text-stroke: .01em #ffffff;
    z-index: 3;
    text-shadow: 2px 2px 5px rgba(150, 150, 150, 0.77);
}

.error-layout .xx-lg {
    font-size: 200%;
    margin: 0 auto;
    z-index: -1;
    color: #e10000;
    text-shadow: 2px 2px 5px rgba(150, 150, 150, 0.77);
}

.error-layout .home-link {
    text-transform: none;
    font-size: 3em;
    padding: .2em 0 .2em 0;
    color: $success;
}

.syserror h1 {
    font-size: 5em;
    color: #f00;
    text-transform: none;
    text-shadow: 2px 2px 5px rgba(150, 150, 150, 0.77);
}

.syserror h2 {
    font-size: 2.8em;
    color: $primary;
    font-weight: bold;
    text-shadow: 2px 2px 5px rgba(150, 150, 150, 0.77);
}

.syserror .text-row {
    position: relative;
    margin-top: -50%;
    margin-left: 100px;
}

.syserror .extra-lg {
    font-size: 20em;
    margin: 0 0 0 115px;
    color: #e10000;
    -webkit-text-stroke: .01em #ffffff;
    z-index: 3;
    text-shadow: 2px 2px 5px rgba(150, 150, 150, 0.77);
}

.syserror .xx-lg {
    font-size: 200%;
    margin: 0 auto;
    z-index: -1;
    color: #e10000;
    text-shadow: 2px 2px 5px rgba(150, 150, 150, 0.77);
}

.syserror .home-link {
    text-transform: none;
    font-size: 3em;
    padding: .2em 0 .2em 0;
    color: $success;
}

@-webkit-keyframes rainbow {
    0% {
        background: #f00;
    }

    20% {
        background: #e10000;
    }

    40% {
        background: #ca1010;
    }

    60% {
        background: #f00;
    }

    80% {
        background: rgb(255, 55, 0);
    }

    100% {
        background: #f00;
    }
}

.c-switcher {
    min-height: 200px;
    background-color: #ea1b1b;
    min-height: 200px;
    -webkit-animation: rainbow 10s infinite;
    -moz-animation: rainbow 10s infinite;
    -o-animation: rainbow 10s infinite;
    animation: rainbow 7s infinite;
    -webkit-animation: rainbow 7s infinite;
    -moz-animation: rainbow 7s infinite;
    -o-animation: rainbow 7s infinite;
    animation: rainbow 10s infinite;
}

.business-not-found {
    background-repeat: no-repeat;
    background-image: url("../abxch/img/abxchbusinessman404sm.png");
    width: 288px;
    height: 288px;
    border: none !important;
    outline: 0 !important;
}

.business-server-error {
    background-repeat: no-repeat;
    background-image: url("../abxch/img/abxchbusinessman500sm.png");
    width: 288px;
    height: 288px;
    border: none !important;
    outline: 0 !important;
}

.error-container {
    max-width: 900px;
    margin: 0 auto;
}

.error-container .img-responsive {
    margin: 0 auto;
}

.error-container p {
    color: #ffffff;
    font-size: 1.5em;
    text-transform: none;
    font-weight: 600;
    word-break: break-word;
}

.error-sm {
    font-size: .9em !important;
    margin: 3em auto;
}

.error-sm p {
    font-size: 1.4em;
}

@-webkit-keyframes errorapparent {
    0% {
        background: rgba(255, 0, 0, 0.75);
    }

    20% {
        background: rgba(225, 0, 0, 0.75);
    }

    40% {
        background: rgba(202, 16, 16, 0.83);
    }

    60% {
        background: rgba(255, 0, 0, 0.83);
    }

    80% {
        background: rgba(255, 55, 0, 0.83);
    }

    100% {
        background: rgba(255, 0, 0, 0.83);
    }
}

.bg-error {
    min-height: 200px;
    -webkit-animation: errorapparent 10s infinite;
    -moz-animation: errorapparent 10s infinite;
    -o-animation: errorapparent 10s infinite;
    animation: errorapparent 10s infinite;
}

.pgerror.fade.in {
    opacity: 1;
    min-height: 200px;
    -webkit-animation: errorapparent 10s infinite;
    -moz-animation: errorapparent 10s infinite;
    -o-animation: errorapparent 10s infinite;
    animation: errorapparent 10s infinite;
}

.pgerror.model-content {
    background-color: rgba(255, 0, 0, 0.75) !important;
}

.pgerror.modal-content {
    position: relative;
    -webkit-background-clip: padding-box;
    border: 0;
    outline: 0;
}

.pgerror .badge-multiline {
    font-size: 1.2em;
    width: 100%;
    white-space: normal;
    margin-bottom: 2em;
    background-color: transparent;
}

#errorModal .modal-content {
    background-color: transparent;
    box-shadow: none;
    border: none;
}

p#display-error {
    font-size: 1.4em;
}


/* ===================================
    BEGIN VALIDATION ERROR MESSAGE
   =================================== */
.input-validation-error {
    border: 2px solid #f00 !important;
    outline: 2px solid #f00 !important;
}

.input-validation-error:focus {
    border-color: $danger;
    -webkit-box-shadow: 1px 1px 10px -2px rgba(255,0,0,1);
    -moz-box-shadow: 1px 1px 10px -2px rgba(255,0,0,1);
    box-shadow: 1px 1px 10px -2px rgba(255,0,0,1);
}

.input-group .form-control .input-validation-error:focus {
    border-color: $danger;
    -webkit-box-shadow: 1px 1px 10px -2px rgba(255,0,0,1);
    -moz-box-shadow: 1px 1px 10px -2px rgba(255,0,0,1);
    box-shadow: 1px 1px 10px -2px rgba(255,0,0,1);
}

input-validation-error #title {
    background-color: #f00;
}

.field-validation-error {
    color: transparent;
    font-size: 1em;
    position: absolute;
    top: 0;
    display: none;
}

.field-validation-error span {
    display: none !important;
}

.field-validation-error:before {
    font-family: FontAwesome;
    content: "\f06a";
    font-size: 1.2em;
    color: #f00;
}

.field-validation-valid {
    display: none;
}

.input-group .field-validation-error:after {
    display: none;
    color: #ffffff;
}

.validation-summary-errors {
    position: fixed;
    top: 130px;
    right: 1%;
    display: table;
    z-index: 10;
    margin: 1em;
}

.validation-summary-errors ul {
    list-style-type: none;
    z-index: 3000;
    position: relative;
}

.validation-summary-valid {
    display: none;
}

.close.alert-close {
    margin-top: -.3em;
    margin-right: -.1em;
    font-size: 1.3em;
}

/* ===================================
    END VALIDATION ERROR MESSAGE
   =================================== */



//500 error
.imageRotateHorizontal {
    -moz-animation: spinHorizontal 3s infinite linear;
    -o-animation: spinHorizontal 3s infinite linear;
    -webkit-animation: spinHorizontal 3s infinite linear;
    animation: spinHorizontal 3s infinite linear;
}

@-moz-keyframes spinHorizontal {
    0% {
        -moz-transform: rotateY(0deg);
        -ms-transform: rotateY(0deg);
        -o-transform: rotateY(0deg);
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }

    100% {
        -moz-transform: rotateY(360deg);
        -ms-transform: rotateY(360deg);
        -o-transform: rotateY(360deg);
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}

@keyframes spinHorizontal {
    0% {
        -moz-transform: rotateY(0deg);
        -ms-transform: rotateY(0deg);
        -o-transform: rotateY(0deg);
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }

    100% {
        -moz-transform: rotateY(360deg);
        -ms-transform: rotateY(360deg);
        -o-transform: rotateY(360deg);
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}

@-ms-keyframes spinHorizontal {
    0% {
        -ms-transform: rotateY(0deg);
        -moz-transform: rotateY(0deg);
        -o-transform: rotateY(0deg);
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }

    100% {
        -ms-transform: rotateY(360deg);
        -moz-transform: rotateY(360deg);
        -o-transform: rotateY(360deg);
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}

@-o-keyframes spinHorizontal {
    0% {
        -o-transform: rotateY(0);
        -moz-transform: rotateY(0);
        -ms-transform: rotateY(0);
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
    }

    100% {
        -o-transform: rotateY(360deg);
        -moz-transform: rotateY(360deg);
        -ms-transform: rotateY(360deg);
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}

@-webkit-keyframes spinHorizontal {
    0% {
        -webkit-transform: rotateY(0deg);
        -moz-transform: rotateY(0deg);
        -ms-transform: rotateY(0deg);
        -o-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }

    100% {
        -webkit-transform: rotateY(360deg);
        -moz-transform: rotateY(360deg);
        -ms-transform: rotateY(360deg);
        -o-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}
//