﻿
.login-manage-settings {
    padding-top: 1em;
    padding-bottom: 1em;
}

.login-main {
    margin-top: 100px;
    margin-bottom: 150px;
}
