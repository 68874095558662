﻿.header-bg-color {
    background-color: $headerBackgroundColor;
}

.main-menu-sidebar{
    background: #eee;
}

.collapse-icon {
    text-align: center;
}

    .collapse-icon:hover {
        cursor: pointer;
    }

.hamburger {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 7.5px;
    font-size: 1.5rem;
}

.overflow-scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    
}

.main-navigation {
    a, button {
        color: #000;
    }

    .active {
        background-color: #464747;

        a {
            color: #fff;
        }
    }

    z-index: 2000;
    width: inherit;
    max-width: inherit;

}

    .main-navigation::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
    }

    .main-navigation::-webkit-scrollbar {
        width: 8px;
        background-color: #F5F5F5;
    }

    .main-navigation::-webkit-scrollbar-thumb {
        background-color: #b5b5b5;
        border: 2px solid #adadad;
        border-radius: 15px;
    }
    
