﻿

.filter-group-dropdown-icon, .filter-menu-icon {
    position: absolute;
    z-index: 900;
    top: 0;
    left: 0;
    width: 100%;
    text-align: right;
    display: block;
}

.filter-panel-btn {
  position:relative;
  z-index:999;
}

.filter-group, .filter-panel-heading {
    position: relative;
}

@media(min-width: 1200px) {
    .filter-body {
        display: block !important;
    }

    .filter-menu-icon {
        display: none;
    }
}

.result-filter {
    vertical-align: text-top;
    z-index: 1053;
    position: relative;
}

.sort-title {
    font-size: .9em !important;
    padding: .2em !important;
    display: block;
    letter-spacing: .06em;
}


.filter-group > .panel-heading {
    padding: .3em;
}

.filter-group label {
    font-size: .75em;
    line-height: .95em;
    color: #444;
}


